// Greyscale
$white: #fff;
$gray-1: #f9f9f9;
$gray-2: #ccc;
$gray-3: #767676;
$gray-4: #515151;
$gray-5: #313131;

// Misc colors
$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$brown: #8f5536;

// My Colors
$light-blue: #E4E9FA;
$blue: #5769FF;
$dark-blue: #555B8B;

$root-font-family: "Anonymous Pro", Menlo, Monaco, "Courier New", monospace;
$root-font-size: 16px;
$root-line-height: 1.5;

$title-font-family: "Eczar", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;

$body-color: $dark-blue;
$body-bg: $light-blue;
$link-color: $blue;

$border-color: $white;

$large-breakpoint: 38em;
$large-font-size: 18px;

$code-font-family: "Anonymous Pro", Menlo, Monaco, "Courier New", monospace;
$code-color: #bf616a;
