/* ===========================================================================*/
/* #LAYOUT*/
/* Styles for managing the structural hierarchy of the site.*/
/* ===========================================================================*/

.container {
  max-width: 72.222222222rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.container--small {
  @extend .container;
  max-width: 38rem;
}

/* Fullscreen div*/

.fullscreen {
  width: 100%;
  margin: 5rem auto;

  @media screen and (min-width: 680px) {
    height: 45rem;
    height: 100vh;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}

/*===== Helpers =====*/

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1;
}
