/* ===========================================================================*/
/* #MASTHEAD*/
/* Super small header above the content for site name*/
/* ===========================================================================*/

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}

.masthead-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;

  a {
    color: inherit;
  }
}
