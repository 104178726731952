/* ===========================================================================*/
/* #MUKE ZAG*/
/* The Mikey Wills zig-zag animation*/
/* ===========================================================================*/

#muke-zag {
  height: 100%;
  width: 100%;
  max-width: 35em;
  padding: 1.2em;
  
  /* First, the dots fade in*/
  .zag-dot{
    opacity: 0;
    -webkit-animation: fadeIn 500ms ease-in-out forwards;
            animation: fadeIn 500ms ease-in-out forwards;
  }
  
  /* Next, the letters flicker on*/
  .zag-letter {
    opacity: 0;
    -webkit-animation-name: flicker;
            animation-name: flicker;
    -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
    -webkit-animation-delay: 450ms;
            animation-delay: 450ms;
    -webkit-animation-direction: reverse;
            animation-direction: reverse;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: steps(17);
            animation-timing-function: steps(17);
  }
  
  /* Last, the connecting stroke is animated*/
  .zag-stroke {
    opacity: 1;
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
  }
  
  #m .zag-stroke {
    -webkit-animation: draw 800ms linear 2800ms forwards;
            animation: draw 800ms linear 2800ms forwards;
  }
  
  #w .zag-stroke {
    -webkit-animation: draw 800ms linear 3800ms forwards;
            animation: draw 800ms linear 3800ms forwards;
  }
}