/* ===========================================================================*/
/* #LISTS*/
/* DL, UL, OL*/
/* ===========================================================================*/

/* Variables*/

$indent: 1.2rem;
$list-break: 2rem;

/* Lists*/

.simple-list {
  list-style: none;
  
  dt {
    font-weight: normal;
  }
  
  dd {
    margin: 0 0 0 $indent;
    
    &.list-button {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      .button {
        display: inline-block;
        min-width: 7rem;
      }
    }
    
  }
  
  .list-section {
    margin-top: $list-break;
  }
}
