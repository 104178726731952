/* ===========================================================================*/
/* #BUTTONS*/
/* ===========================================================================*/

.button {
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    bottom: -0.15em;
    left: 0.35em;
    width: 101%;
    z-index: -1;
    height: 0.6em;
    background-color: #fff;
    -webkit-transition: all .175s ease;
    transition: all .175s ease;
  }
  
  &:hover {
    cursor: pointer;
    text-decoration: none;
    
    &:before {
      bottom: -0.15em;
      left: 0.5em;
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
  }
}
