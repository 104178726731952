/* ===========================================================================*/
/* #HOME*/
/* ===========================================================================*/

body.page-Home {
  position: relative;
  max-width: 100%;    
  overflow-x: hidden;
  transform: translateZ(1000px);
  transform-style: preserve-3d;
  -webkit-animation: bgFade 2500ms ease-in-out forwards;
          animation: bgFade 2500ms ease-in-out forwards;
}

.home-bio {
  margin-bottom: 27rem;

  @media screen and (min-width: 500px) {
    margin-bottom: 25rem;
  }

  @media screen and (min-width: 680px) {
    margin-bottom: 8rem;
  }
}

.bio-headline {
  font-size: 12vw;
  line-height: 1.25;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  left: -1.75vw;
  margin-top: 10rem;
  z-index: -1;

  @media screen and (max-width: 680px) {
    bottom: 10.5rem;
  }

  @media screen and (min-width: 860px) {
    margin-top: 7.5rem;
  }
  
  @media screen and (min-width: 1500px) {
    font-size: 11rem;
    margin-top: 5rem;
    left: -1rem;
  }
}

.bio-visual {
  width: 28.888888889rem;
  height: 38rem;
  background-image: url('/assets/images/muke-purp.jpg');
  background-size: 26rem;
  background-repeat: no-repeat;
  background-position: 8rem top;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -2;
  opacity: 0;
  -webkit-animation: fadeIn 2000ms ease-in-out forwards;
          animation: fadeIn 2000ms ease-in-out forwards;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;

  @media screen and (min-width: 500px) {
    height: 47.777777778rem;
    background-position: 8rem bottom;
  }

  @media screen and (min-width: 860px) {
    background-size: contain;
    background-position: center bottom;
    left: 55%;
  }

  @media screen and (min-width: 1500px) {
    left: 900px;
    right: auto;
  }
}

